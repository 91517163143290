import axios from "axios";
import vue from "vue";
// start()  done
axios.defaults.baseURL = "https://app.youdongtiyu.com:5000/";

// 请求拦截器
axios.interceptors.request.use((config) => {
  // 这个config里面要携带token
  return config;
});
// 响应拦截
axios.interceptors.response.use((config) => {
  return config.data;
});
vue.prototype.$http = axios;
