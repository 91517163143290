<template>
  <div class="maxBox">
    <div class="content">
      <div class="lfet" @click="goHome">
        <img class="logo" src="../assets/img/logo.png" alt="logo" />
        <h1>友动(北京)体育发展有限公司</h1>
      </div>
      <div class="right">
        <router-link to="/about" replace>关于友动</router-link>
        <router-link to="/shop" replace>现有球馆</router-link>
        <router-link to="/consociation" replace>项目合作</router-link>
        <!-- <router-link to="/partner" replace>咨询(收费)</router-link> -->
        <router-link to="/contact" replace>联系我们</router-link>
      </div>
      <!-- 小屏时  显示 -->
      <!-- <button class="showNavBar" @click="showNavBar">
        <img src="@/assets/img/menu.png" alt="菜单按钮" />
      </button>
      <div class="minright" v-if="isShow" @click="showNavBar">
        <router-link to="/about">关于友动</router-link>
        <router-link to="/shop">现有球馆</router-link>
        <router-link to="/consociation">项目合作</router-link>
        <router-link to="/partner">合作伙伴</router-link>
        <router-link to="/contact">联系我们</router-link>
      </div> -->
    </div>
    <div class="placeholderBox" v-if="isShow"></div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "NavBar",
  data() {
    return {
      isShow: false,
    };
  },
  components: {},
  methods: {
    showNavBar() {
      this.isShow = !this.isShow;
    },
    goHome() {
      if (this.$route.path == "/about") {
        return;
      } else {
        this.$router.push("/about");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  z-index: 999;
  .lfet {
    flex: 1;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    padding-top: 5px;
    text-shadow: 5px 5px 5px black, 0px 0px 2px black;
    cursor: pointer; /*鼠标悬停变小手*/
    .logo {
      width: 75px;
      height: 75px;
      margin-right: 20px;
    }
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    a {
      color: #fff;
      font-weight: 600;
      text-decoration: none;
      padding: 10px 0;
      text-shadow: 5px 5px 5px black, 0px 0px 2px black;
    }
    .router-link-active {
      padding: 10px 0;
      font-size: 18px;
      font-weight: bolder;
      border-bottom: 2px solid #0286fe;
    }
  }
  .minright {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    background-color: #f5f5f5;
    a {
      display: block;
      padding: 8px 0;
      border-bottom: 1px solid #ecebeb;
      text-decoration: none;
      color: #000;
    }
    a.router-link-exact-active {
      color: #555;
      font-weight: bolder;
      border-bottom: 2px solid #0286fe;
    }
  }
  // @media screen and (min-width: 901px) {
  //   .showNavBar {
  //     display: none;
  //   }
  // }
  // @media screen and (max-width: 900px) {
  //   .placeholderBox {
  //     width: 100%;
  //     height: 600px;
  //   }
  //   .right {
  //     display: none;
  //   }
  //   .showNavBar {
  //     display: block;
  //     margin-right: 15px;
  //     img {
  //       width: 30px;
  //       height: 30px;
  //     }
  //   }
  // }
}
</style>
