<template>
  <div class="maxBox">
    <div class="content bottom">
      <div class="lfet">
        <div class="logeBOX">
          <img class="logo" src="../assets/img/logo.png" alt="logo" />
          <h1>友动(北京)体育发展有限公司</h1>
        </div>
        <div class="message">
          <div>咨询热线：18201619252</div>
          <div>商务合作：18201619252 苗总</div>
          <p>
            © copyright 2021 友动（北京）体育发展有限公司 All rights reserved.
          </p>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            title="京ICP备2021028073号"
            >京ICP备2021028073号</a
          >
        </div>
      </div>
      <div class="right">
        <img class="qrcode" src="../assets/img/qrcode.jpg" alt="" />
        <p>扫码查看友动体育小程序</p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  methods: {},
};
</script>

<style lang="less" scoped>
.maxBox {
  width: 100%;
  background: #000;
  box-shadow: 0px 5px 10px 20px #000;
}
.bottom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 30px 0 10px;
  .lfet {
    margin-left: 20px;
    flex: 1;
    color: #fff;
    .logeBOX {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #393a3d;
      h1 {
        font-size: 36px;
        text-shadow: 5px 5px 5px #fff, 0px 0px 2px #fff; // 没错这里设置了两个 shadow}
      }
      .logo {
        width: 80px;
        height: 80px;
        margin-right: 20px;
      }
    }
    .message {
      padding-top: 10px;
      font-family: Microsoft YaHei;
      font-size: 14px;
      color: #888;
      p {
        display: flex;
        align-items: center;
        span {
          font-size: 20px;
        }
      }
      a {
        color: #888;
        text-decoration: none;
      }
    }
  }
  .right {
    padding: 0 60px;
    color: #fff;
    text-align: center;
    .qrcode {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
