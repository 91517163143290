import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/about",
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../components/about.vue"),
  },
  {
    path: "/shop",
    name: "shop",
    component: () => import("../components/shop.vue"),
  },
  {
    path: "/consociation",
    name: "consociation",
    component: () => import("../components/consociation.vue"),
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("../components/partner.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../components/contact.vue"),
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../views/payment.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
