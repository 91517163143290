<template>
  <div id="app">
    <navBar />
    <router-view />
    <pageButton />
  </div>
</template>
<script>
import navBar from "./views/NavBar.vue";
import pageButton from "./views/pageBottom.vue";
export default {
  components: {
    navBar: navBar,
    pageButton: pageButton,
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.maxBox {
  max-width: 100vw;
}
.content {
  max-width: 1280px;
  margin: auto;
  // border: 1px solid red;
}
</style>
